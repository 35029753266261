@import 'variables.scss';

/* Override bootstrap link variable text-decoration underline (BS5) */
$link-decoration: none;
$link-hover-decoration: underline;
$disabled-color: var(--bs-danger);

@import 'node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import 'forms.scss';
@import 'nested-tables.scss';
@import 'helpers.scss';
@import 'freeze-table.scss';
@import 'animations.scss';
@import 'mixins.scss';
@import 'feed.scss';

html {
  height: 100%;
}

body {
  padding-top: 40px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;

  @media (max-width: 1440px) {
    overflow-x: auto;
  }
}

td {
  padding: 0;
}

/* Override btn:disabled border-color (BS5)*/

/* Override bootstrap placeholder component (BS5) */
.placeholder {
  cursor: pointer;
  background-color: transparent;
  opacity: 1;
}

.radio-group-disabled {
  opacity: 0.7;
}

/* For badge with warning themes bg-color apply black text-color*/

.badge.bg-warning {
  color: #212529;
}

/* Hover && Focus || Focus events on .btn class add below styles */

.btn:hover:focus,
.btn:focus {
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(51 122 183 / 25%);
}

/* Class .close from BS4 (BS5) */

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

/* (BS5) */

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--bs-body-color);
  text-shadow: 0 1px 0 var(--bs-body-bg);
  opacity: 0.5;
}

/* (BS5) */

.ngb-dp-today {
  color: var(--bs-danger) !important;
  font-weight: bold;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

.toolbar-container {
  align-items: center;
  flex-wrap: nowrap;

  .group {
    display: flex;
    flex-wrap: nowrap;

    button {
      height: 100%;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }

    .dropdown {
      button {
        display: block;
      }
    }
  }

  @media (max-width: 1440px) {
    .ms-auto {
      margin-left: 0 !important;
    }
  }
}

.btn-default {
  color: var(--bs-btn-color);
  background-color: var(--bs-body-bg);
  border-color: var(--bs-border-color) !important;

  &:hover {
    background-color: var(--bs-btn-bg-hover);
    border-color: var(--bs-btn-border-hover);
  }
}

[radiogroup] > .btn-default.active,
.btn-check.active,
.btn-check:checked + .btn-default {
  background-color: var(--bs-btn-bg-hover);
  border-color: var(--bs-btn-border-hover);
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-link:focus {
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}

.modal-title {
  @include trim;
}

.block-ui-wrapper {
  background: none !important;
}

.input-clear {
  font-size: 26px;
  line-height: 1px;
  position: absolute;
  z-index: 900;
  top: 14px;
  right: 44px;
  font-weight: lighter;
  color: var(--bs-text-minor);
  cursor: pointer;
}

input.ellipsis {
  -moz-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// Обрезание текста по контейнеру.
.trim {
  @include trim;
}

.trim-blue {
  @include trim;
  color: $blue;
}

.trim-multiline {
  overflow: hidden;
  text-overflow: ellipsis;
}

// Анимация вращения.
.rotate {
  display: inline-block;
  -webkit-animation: spin 1.2s linear infinite;
  -moz-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
}

input::placeholder,
textarea::placeholder {
  /* Firefox, Chrome, Opera */
  color: var(--bs-gray-400) !important;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--bs-gray-400) !important;
}

ngx-dropzone,
.drop-zone-container {
  border-radius: 0px !important;
  background: transparent !important;

  &.ngx-dz-hovered {
    background-color: aliceblue; /** TODO: add theme variable */
    border-color: var(--bs-selected-color-o8) !important;
  }

  ngx-dropzone-label {
    z-index: 1 !important;

    span {
      font-family: $font-family-base !important;
      font-size: $font-size-base !important;
      font-weight: $font-weight-base !important;
      line-height: $line-height-base !important;
      color: var(--bs-body-color) !important;
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  position: relative;

  height: auto;
  border-color: transparent !important;

  &.hovered,
  &.awaited {
    z-index: 5;

    * {
      pointer-events: none;
    }

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      width: 100%;
      height: 100%;

      background: #37373733; /** TODO: add theme variable **/
      border: 2px dashed var(--bs-primary) !important;
    }

    &::after {
      content: attr(data-title);
      color: var(--bs-body-color);
      text-transform: uppercase;
      font-weight: 500;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;

      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

ngb-rating {
  &:focus {
    outline: none;
  }

  .star {
    font-size: 1.4rem;
    color: var(--bs-text-disabled);

    &.filled {
      color: var(--bs-gold);
    }
  }
}

button:disabled {
  cursor: not-allowed;
}

.dropdown-menu {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -ms-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-transform: translate3d(0, 0, 0);
  max-width: 450px;

  .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bg-info {
  background-color: #7cb5ec !important;
}

.form-check-inline {
  label {
    margin: 0 !important;
  }
}

#beacon-container {
  position: absolute;
  z-index: 1100;
}

/** Стиль календарика */
ngb-datepicker {
  .ngb-dp-navigation-chevron {
    color: var(--bs-text-minor);
  }

  .ngb-dp-weekday {
    color: var(--bs-navbar) !important;
  }
}

.control-label {
  margin-bottom: 0.5rem;
}

hr {
  opacity: 0.1;
}

/* Некоторые иконки Bootstrap четко отображаются в размере 15px.*/
.bi-15 {
  font-size: 15px !important;
  line-height: 14px !important;

  &::before {
    width: 14px !important;
  }
}

th {
  font-weight: 500;
}

tfoot > tr > td {
  font-weight: 500;
}

.subheader {
  margin-top: 10px;
  margin-bottom: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__props {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
  }

  &__prop {
    display: flex;
    max-width: 350px;
  }

  &__label {
    color: var(--bs-text-minor);
    padding-right: 0.5rem;
  }

  &__value {
    @include trim;
  }

  &__control {
    .input-group {
      flex-wrap: nowrap;
    }
  }
}

.tooltip-break-n {
  white-space: pre;
  text-wrap: wrap;
  text-align: start;
}

.tooltip-max-content {
  .tooltip-inner {
    width: max-content;
  }
}

.active-task-panel {
  width: fit-content !important;
  min-width: 600px;
  max-width: 1200px;
}

.scroll-y-overflow {
  max-height: 300px;
  overflow-y: auto;
}

$animation-time: 500ms;

.water-animation {
  animation-name: waterDrop;
  animation-duration: $animation-time;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.fade-animation {
  animation-name: fade;
  animation-duration: calc($animation-time / 2);
  animation-delay: calc($animation-time / 2);
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.highcharts-background {
  fill: var(--bs-body-bg) !important;
}

.mention {
  color: var(--bs-primary);
  cursor: pointer;
}

// TODO: move to separate file
.board__task {
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  text-decoration: none;
  color: inherit;

  background: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius-lg);
  user-select: none;
  cursor: grab;
  transition: all 0.5s;

  &:hover {
    text-decoration: none;
    //  background: var(--bs-dark-o1);
  }

  &-menu:hover {
    background-color: var(--bs-gray-400);
  }

  &-property {
    display: flex;

    &--first {
      display: flex;
      align-items: center;

      :first-child {
        width: calc(100% - 30px);
      }
    }

    &--name {
      align-items: baseline !important;
    }

    &--boolean {
      :first-child {
        width: 100% !important;
      }
    }
  }

  &-link {
    .board__task-inactive & {
      text-decoration: line-through;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .off-canvas-link {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none;
    cursor: pointer;

    &:hover {
      --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
      text-decoration: underline;
    }
  }

  .boolean {
    display: grid;
    gap: 3px;
    grid-template-columns: fit-content(calc(100% - 20px)) 20px;

    span {
      display: block;
    }
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &__info {
      display: flex;
      align-items: center;
      gap: 8px;

      .avatar-img {
        width: 1.7rem;
        border-radius: 50%;
      }
    }
  }
}

// TODO: move to separate file
.board__column-header {
  display: flex;
  flex: none;

  padding: 8px;
  width: var(--tasks-board-column-width);
  border-radius: var(--bs-border-radius-lg);
  background: var(--bs-secondary-bg);

  font-weight: 500;
  line-height: 2;

  &-menu:hover {
    background-color: var(--bs-gray-400);
  }
}

.list-container {
  width: calc(50% - 0.3rem);
}

.list-header {
  margin-bottom: 0.2rem;

  display: flex;

  font-weight: 500;

  & > span {
    @include trim;

    flex-grow: 1;
    flex-shrink: 1;
  }
}

.list {
  @include scroll;

  position: relative;

  height: 270px;
  overflow-y: auto;

  border: 0.125rem dashed transparent;
  border-radius: var(--bs-border-radius);

  transition: border-color 0.2s;

  &--available {
    margin-right: 0.2rem;
  }

  &__empty {
    position: absolute;

    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--bs-text-minor);
    text-align: center;
    text-transform: uppercase;
  }

  &__item {
    position: relative;

    height: 35px;

    display: flex;
    align-items: center;

    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color);
    border-bottom: none;

    opacity: 1;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom: 1px solid var(--bs-border-color);
    }

    &:only-child {
      border: 1px solid var(--bs-border-color) !important;
      border-radius: $border-radius !important;
    }

    &.disabled {
      color: var(--bs-text-disabled);

      div {
        cursor: default !important;
      }
    }

    &:not(.draggable) {
      .handle {
        cursor: not-allowed;
      }
    }

    &.sortable-ghost {
      .wp-checkbox {
        display: none;
      }
    }

    & > div {
      position: relative;

      padding: 7px 7px 7px 12px;

      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;

      user-select: none;

      &:hover {
        .list-item-delete {
          visibility: visible;
        }
      }
    }

    & > div.list-item-select {
      flex-basis: 130px;
      flex-shrink: 0;
      height: 100%;
      border-left: 1px solid var(--bs-border-color);
    }

    & button.list-item-delete {
      position: absolute;
      top: -5px;
      right: 5px;
      font-size: 26px;
      font-weight: lighter;
      border: none;
      background-color: transparent;
      visibility: hidden;
      margin: 0 !important;

      &:hover {
        color: var(--bs-body-color);
        text-decoration: none;
        opacity: 0.5;
      }
    }

    .handle {
      cursor: move;
    }
  }

  .chosen-selected,
  .ghost-selected,
  .drag-selected {
    background-color: var(--bs-selected-color-o15);
  }
}

.fire__line {
  --height: 100px;
  --rotate: 0;
  --bg-color: rgba(95, 145, 255, 1);
  --filter-color: rgba(105, 155, 255, 1);

  position: fixed;
  z-index: 9999;

  height: 3px;
  background-color: transparent;

  transform: rotate(var(--rotate));

  user-select: none;
  pointer-events: none;

  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 3px;
    border-radius: 999px;

    background: linear-gradient(-45deg, var(--bg-color), rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px var(--filter-color));
    will-change: height, transform;

    animation:
      tail 1.5s forwards,
      shooting 1.5s forwards;
  }
}

@keyframes tail {
  0% {
    height: 0;
  }

  30% {
    height: calc(var(--height) / 2);
  }

  100% {
    height: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(var(--height) * 1.5));
  }
}

tmt-rich-editor-box,
.comment-text,
.interaction-item__description {
  blockquote {
    border-left: 3px solid var(--bs-link-color);
    padding-left: 12px;
    position: relative;
    white-space: nowrap;
  }

  code {
    padding: 0.1em 0.4em 0.15em;
    border-radius: 4px;
    background-color: var(--bs-gray-400);
  }

  hr {
    border: 0;
    height: 0.25rem;
    margin: 1.25rem auto;
    background-color: var(--bs-gray-200);
    opacity: 1;
    width: calc(100% - 0.1rem);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0 !important;
  }

  pre code {
    display: block;
    padding: 0.4rem;
  }

  li {
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.data-not-saved-indicator-to-top {
  position: relative;

  wp-data-not-saved-indicator {
    position: fixed;
    top: 5.25rem;
    z-index: 90;

    width: calc(100% - $nav-panel-width - var(--main-scrollbar-width));
    transform: translateX((-20px));

    .compact-mode & {
      width: calc(100% - var(--main-scrollbar-width));
    }

    ngb-alert {
      padding: 0.125rem 0.5rem;
      border-color: transparent;
      border-radius: 0;
    }
  }
}

.ml-auto {
  margin-left: auto;
}
