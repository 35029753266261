@import './variables.scss';

$icon-size: 30px;
$width: 800px;

.feed {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &--work-log {
    wp-filter {
      width: 100%;

      .input-group {
        width: 100% !important;
        max-width: 450px;
      }
    }

    .feed__toolbar {
      button {
        text-wrap: nowrap;
      }
    }
  }
}

.feed-item {
  position: relative;

  width: 100%;
  max-width: $width;
  padding: 0.7rem;
  border-radius: var(--bs-border-radius);

  display: flex;
  gap: 1.6rem;

  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: var(--bs-secondary-bg);

    .feed-item {
      &__icon,
      &__badge--contrast {
        background-color: var(--bs-light);
      }

      &__actions {
        opacity: 1;
      }
    }
  }

  &__actions {
    margin-left: auto;

    display: flex;
    gap: 0.25rem;

    opacity: 0;

    transition: opacity 0.15s;

    .bi {
      color: var(--bs-text-minor);
    }

    .btn-icon {
      padding: 0.25rem;
    }
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
    background-color: var(--bs-secondary-bg);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--bs-body-color);
    font-size: 1rem;

    transition: background-color 150ms ease-in-out;
  }

  &__icon-type {
    position: relative;

    width: $icon-size;
    overflow: visible;

    display: flex;
    align-items: start;
    justify-content: center;
  }

  &__vertical-line {
    position: absolute;
    top: 0;
    left: 14.5px;
    z-index: -1;

    width: 5px;
    height: calc(100% + $icon-size);
    border-left: 2px dashed var(--bs-gray-500);
  }

  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
  }

  &__header {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__badge {
    min-width: 80px;
    width: fit-content;
    height: $icon-size;

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    font-size: 0.9rem;

    &--contrast {
      background-color: var(--bs-secondary-bg);
      transition: background-color 150ms ease-in-out;
    }
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;

    &-avatar {
      @include default-avatar;
    }

    &-name {
      max-width: 200px;
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__details {
    width: 100%;
    max-width: calc($width - 100px);

    display: flex;
    gap: 0.3rem;

    &-item {
      position: relative;

      padding-right: 0.7rem;
      display: flex;

      &:has(+ .feed-item__details-item) {
        &::after {
          content: '/';
          position: absolute;
          right: 0;
        }
      }
    }
  }

  &__description {
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-line;
  }

  &__fields {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    p {
      margin: 0;
    }
  }
}

@mixin feed-item-custom-fields {
  display: contents;

  ::ng-deep {
    .row {
      display: contents;

      > div {
        display: contents;
      }
    }

    .form-group {
      margin: 0;

      label {
        margin: 0;

        font-weight: 400;
        font-size: 0.875em;
        color: var(--bs-secondary);
      }

      p {
        padding: 0;
        height: 100%;
        line-height: normal;
      }
    }
  }
}
