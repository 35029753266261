@keyframes magic {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(var(--height), 100px);
  }
}

@keyframes shine {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    text-shadow: 1px 1px 8px #000000d0;
  }

  10%,
  70% {
    text-shadow: 1px 1px 8px #ddc614;
  }

  30%,
  90% {
    text-shadow: 1px 1px 8px #14dd3f;
  }

  50% {
    text-shadow: 1px 1px 8px #fff;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes waterDrop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.8);
  }
}
