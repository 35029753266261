[wpFreezeTable] {
  [name='toolbar'] {
    width: 100%;
  }

  [name='scroll-table-header'],
  [name='scroll-table-footer'],
  [name='toolbar'] {
    background-color: var(--bs-body-bg);

    &.fixed {
      position: fixed;
      z-index: 3;
    }

    table {
      margin: 0;
    }
    th {
      border-bottom-width: 1px;
    }
    td {
      border-top-width: 1px;
    }
  }

  [name='scroll-table-header'],
  [name='scroll-table-footer'],
  [name='scroll-table-body'] {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }

  [name='toolbar'] {
    &.fixed {
      z-index: 13;
    }
  }

  [name='right'] {
    [name='scroll-table-header'] {
      overflow: auto;
      &.fixed {
        z-index: 11;
      }
    }

    [name='scroll-table-footer'] {
      overflow: auto;
      &.fixed {
        z-index: 8;
      }
    }

    [name='scroll-table-body'] {
      overflow: auto;
    }
  }

  [name='left'] {
    [name='scroll-table-header'] {
      &.fixed {
        z-index: 12;
      }
    }

    [name='scroll-table-footer'] {
      &.fixed {
        z-index: 9;
      }
    }

    [name='scroll-table-body'] {
      // Blur text fix
      // Hack to add hardware acceleration to the animation
      backface-visibility: hidden;

      // NOTE: glitch fix DEV-2703
      .collapsing & {
        backface-visibility: visible !important;
      }

      &.fixed {
        z-index: 7;
      }
    }
  }

  [name='scroll-table-body'] {
    table {
      margin: 0;
    }
  }

  [name='scroll-table-footer'].fixed {
    box-shadow: 0 -8px 10px -6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 -8px 10px -6px rgba(0, 0, 0, 0.1);
  }

  [name='scroll-table-header'].fixed {
    -webkit-box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }

  [name='scroller-container'] {
    overflow-x: auto;
    display: none;
    &.fixed {
      position: fixed;
      z-index: 5;
      background-color: var(--bs-body-bg);
    }

    z-index: 10;
    & > div {
      height: 1px;
      @supports (-moz-appearance: none) {
        height: 15px;
      }
      background: var(--bs-body-bg);
    }
  }

  .wp-shadow {
    -webkit-box-shadow: 8px 0 10px -6px rgba(0, 0, 0, 0.1);
    box-shadow: 8px 0 10px -6px rgba(0, 0, 0, 0.1);
  }
  .scrolled {
    z-index: 3;
  }
  .table:not(.stop-context) {
    .input-group > .form-control:focus,
    .input-group .btn {
      z-index: 0;
    }
  }
}

[wpFreezeTable].in-container {
  height: 100%;

  [name='scroll-table-header'] {
    &.fixed {
      position: absolute;
      top: 0;
      z-index: 3;
    }
  }

  [name='scroll-table-footer'] {
    &.fixed {
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  }
  [name='scroller-container'] {
    &.fixed {
      position: absolute;
      bottom: 0;
    }
  }
}
