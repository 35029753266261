$scroll-width: 6px;
$scroll-color: var(--bs-text-disabled);

%scroll {
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  &:hover {
    scrollbar-color: $scroll-color transparent;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: $scroll-width;
    border-radius: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $scroll-color;
  }
}

@mixin scroll {
  @extend %scroll;
}

@mixin scroll-fluid {
  @extend %scroll;
  overflow-y: overlay; // deprecated - left for backward compatibility with some browsers
  scrollbar-gutter: stable;
}

@mixin scroll-over {
  @extend %scroll;

  scrollbar-width: none;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 0;
  }

  &:hover {
    scrollbar-width: thin;

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar {
      width: $scroll-width;
    }
  }
}

@mixin btnIcon {
  padding: 5px 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  i.bi-15 {
    line-height: normal;
  }
}

// Default padding and border for `td` of `.wp-nested-table`
@mixin cell-padding {
  border: 1px solid var(--bs-border-color);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
}

@mixin cell-height {
  height: 35px;
  max-height: 35px;
}

/// Spinner
///
/// @name spinner
///
/// @param {string} $size [30px] - circle size
/// @param {time} $speed [0.6s] - animation duration
/// @param {string} $circleColor [#428bca26] - color of circle disc
/// @param {string} $spinColor [#428bcacc] - spin color
///
@mixin spinner(
  $size: 30px,
  $speed: 0.6s,
  $circleColor: var(--bs-selected-color-o15),
  $spinColor: var(--bs-selected-color-o8)
) {
  margin: 2px auto;
  height: #{$size};
  width: #{$size};

  border-left: 2px solid #{$circleColor};
  border-right: 2px solid #{$circleColor};
  border-bottom: 2px solid #{$circleColor};
  border-top: 2px solid #{$spinColor};
  border-radius: 100%;

  animation: rotation #{$speed} infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}
