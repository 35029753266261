form {
  .form-group {
    margin-bottom: 15px;
    label {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .form-check {
    label {
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  .accordion--custom {
    .accordion-body {
      padding: 0;
    }

    .accordion-item {
      border: none;
    }

    .accordion-button {
      background-color: var(--bs-body);
      border-color: var(--bs-border-color);
      font-weight: 500;
      box-shadow: none;
      border-radius: 0;
      padding: 1rem 0 0.25rem;

      &:focus {
        border-color: var(--bs-border-color);
      }

      &.collapsed {
        border-radius: 0 !important;
      }

      &:not(.collapsed) {
        &::before {
          background-image: var(--bs-accordion-btn-active-icon);
          transform: var(--bs-accordion-btn-icon-transform);
        }
      }

      &::before {
        width: 1rem;
        height: 1rem;
        margin-right: 10px;
        content: '';
        background-image: var(--bs-accordion-btn-icon);
        transition: (var(--bs-accordion-btn-icon-transition));
        --bs-accordion-btn-icon-transform: rotate(0deg);
        transform: rotate(-90deg);
      }

      &::after {
        display: none;
      }
    }
  }
}

form.form {
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  div.actions {
    padding-top: 20px;
  }
  & > .container-fluid {
    padding: 0;
  }
}

form.modal-form {
  .modal-header {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    cursor: move;

    padding: 15px;
  }

  .modal-footer {
    display: block;
    text-align: right;
  }
}

.modal-content {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.btn-close {
  opacity: 0.2;
  transition: opacity 0.1s;

  &:focus {
    box-shadow: none;
    opacity: 0.2;
  }

  &:hover {
    opacity: var(--bs-btn-close-hover-opacity) !important;
  }
}

.toolbar-container {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-top: 15px;
  gap: 10px 15px;

  .group {
    & > *:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.dropdown-scroll {
  max-height: 350px;
  overflow-y: auto;
}

.required-indicator::after {
  content: '*';
  padding-left: 3px;
  color: var(--bs-danger);
}

.invalid-tab:not(.active) {
  border-bottom: 1px solid var(--bs-danger) !important;
}

/* Right-side forms. */
.right-side {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  height: 100vh;

  & > .modal-content {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
    border-radius: 0 !important;
    border: none;
    height: 100vh;
  }
}

.multiline-item {
  &__search {
    padding: 10px;

    .input-clear {
      top: 27px;
      right: 20px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: auto;

    max-height: 300px;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &--default-mode {
      grid-template-columns: auto 30px;

      .flag-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .multiline-item__row--active {
        .flag-icon {
          background: var(--bs-selected-color);
        }
      }
    }
  }

  &__row {
    display: contents;

    &:hover,
    &.multiline-item__row--active {
      input,
      label,
      div {
        cursor: pointer;
        background-color: var(--bs-selected-color);
        color: var(--bs-light);
      }
    }
  }

  &__name {
    padding: 5px;
    padding-right: 10px;
    user-select: none;
    font-weight: normal !important;
    margin: 0 !important;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    padding-left: calc(1.5rem + 7px);
    margin-bottom: 0;

    input {
      margin-top: 0.1rem;
      user-select: none;
      pointer-events: none;
    }
  }

  &__number {
    padding: 5px;
    padding-left: 10px;
    user-select: none;
    overflow: hidden;
  }

  &__empty {
    text-align: center;
    padding: 3px;
  }
}

.error-tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    background-color: var(--bs-danger);
    white-space: pre-wrap;
    max-width: fit-content;
    text-align: left;
  }

  &.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: var(--bs-danger);
  }
  &.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: var(--bs-danger);
  }
  &.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: var(--bs-danger);
  }
  &.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: var(--bs-danger);
  }
}

.ProseMirror {
  white-space: pre-wrap;
  overflow-x: auto;

  &:focus-visible {
    outline: none;
  }
}

.comments-input {
  display: block;

  width: 100%;
  min-height: 60px;

  border: none;
  outline: none;
  background-color: var(--bs-body-bg);

  resize: none;
}
