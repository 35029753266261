@use 'sass:map';
@import 'variables.scss';
@import 'mixins.scss';

.wp-checkbox {
  display: flex;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
  }

  label:after {
    content: '';
    display: table;
    clear: both;
  }

  .cr {
    border: 1px solid #a9a9a9;
    border-radius: 0.25em;
    width: 1.3em;
    height: 1.3em;
    margin: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cr .cr-icon {
    font-size: 1em;
    line-height: 0;
  }

  input[type='checkbox'] {
    display: none;
  }

  label input[type='checkbox'] + .cr > .cr-icon {
    opacity: 0;
  }

  label input[type='checkbox']:checked + .cr > .cr-icon {
    opacity: 1;
  }

  label input[type='checkbox']:disabled + .cr {
    opacity: 0.5;
  }

  span.title {
    padding-left: 0.2rem;
    @include disable-selecting;
  }
}

// Отключение выделения текста.
.disable-selecting {
  @include disable-selecting;
}

/*Таблица в контроллах выбора*/
.selecting-list {
  width: 100%;
  table-layout: fixed;

  td {
    height: 30px;
    line-height: 30px;
    border: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.selecting-list-hover tr:hover {
  background-color: var(--bs-selected-color);
  color: var(--bs-light);
}

@mixin expanding-area {
  background-color: var(--bs-body-bg);
  border: 1px;
  border-color: var(--bs-border-color);
  border-style: solid;
  border-radius: $border-radius !important;
  margin: 0;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -ms-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-transform: translate3d(0, 0, 0);
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  min-width: min-content;
}

// Оформление раскрывающихся областей
.expanding-area {
  position: absolute;
  @include expanding-area;
  top: 37px;
  z-index: 7;
}

.fixed-expanding-area {
  @include expanding-area;
  z-index: 7;
}

.expanding-area-vertical-scroll {
  max-height: 350px;
  overflow-y: auto;
}

.text-gray {
  color: var(--bs-text-minor);
}

.text-clickable {
  cursor: pointer;
}

.btn-link-content {
  display: contents;
}

table.compact-table {
  td,
  th {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.avatar-cell {
  padding-left: 40px !important;
  position: relative;

  & > img {
    @include small-avatar;
  }
}

/* Medium размер модальных окон. */
@media (min-width: 992px) {
  .modal-md {
    max-width: 700px;
  }
}

.modal-xxl {
  width: 1300px !important;
  max-width: 1300px !important;
}

.modal-full {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
  padding: 25px;
  height: 100%;

  .modal-content {
    height: 100%;
  }
}

.modal-really-full {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;

  height: 100%;

  & > .modal-content {
    height: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
}

.modal-dialog.full-height {
  .modal-content {
    height: calc(100vh - 75px);
  }
}

.modal-transparent {
  .modal-content {
    background: map.get($colors, 'dark-o1');
  }
}

.modal-overflow {
  .modal-content {
    overflow: hidden;
  }
}

.btn-indicator {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  cursor: default !important;
}

/* Popover block with arrow.*/
.popover-box {
  position: absolute;
  background-color: var(--bs-body-bg);
  border: 1px;
  border-color: var(--bs-border-color);
  border-style: solid;
  margin: 0px;
  z-index: 10;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -ms-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: $border-radius;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--bs-body-bg);
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: var(--bs-border-color);
    border-width: 11px;
    margin-left: -11px;
  }

  &.top {
    &:after,
    &:before {
      top: 100%;
      border-bottom-color: rgba(255, 255, 255, 0);
      border-top-color: var(--bs-border-color);
    }

    &:before {
      border-bottom-color: rgba(255, 255, 255, 0);
      border-top-color: var(--bs-border-color);
    }

    &:after {
      border-top-color: var(--bs-body-bg);
    }
  }
}

.btn-action {
  padding: 0;
  text-decoration: none;
  color: var(--bc-text-minor);

  &:hover,
  &:active,
  &:focus {
    color: var(--bs-primary);
    text-decoration: none;
  }
}

.help-action {
  text-decoration: none;
  color: var(--bc-text-minor);
  cursor: pointer;
}

.wp-action {
  color: var(--bs-primary);
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: var(--bs-primary-hover);
  }
}

.dropdown-toggle.without-caret::after {
  display: none !important;
}

.text-help-action {
  cursor: pointer;
  color: $gray-700;

  &:hover {
    color: $gray-900;
  }
}

/* ACCORDION STYLES */
ngb-accordion {
  .card {
    overflow: initial !important;

    .card-header {
      background-color: none !important;
      padding: 0.5rem !important;
      border-bottom: none;

      .fa {
        font-size: 1.1rem;
      }

      .btn-link {
        color: var(--bs-body-color);
      }

      .btn-link:hover,
      .btn-link:focus,
      .btn-link:active {
        text-decoration: none;
      }
    }
  }
}

.nav-underline {
  gap: 1rem;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 0.125rem solid transparent;

    &:hover,
    &:focus {
      border-bottom-color: currentcolor;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    font-weight: 500;
  }
}

.table-layout-fixed {
  table-layout: fixed !important;
}

label.group,
button.group {
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  border-bottom: var(--bs-border-color) 1px solid;
  border-radius: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-150 {
  width: 150px;
}
