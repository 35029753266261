$app-select-color: #989898;
$nav-panel-width: 200px;
$nav-panel-short-width: 25px;
$action-panel-height: 45px;
$font-weight-bold: 500;
$expanding-area-width: 232px;

/* Фон выделения. */
$wp-nested-selected-color: var(--bs-tertiary-bg);

/* #region Переопределение переменных Bootstrap. */
$blue: #337ab7;
$font-size-base: 0.875rem;
$modal-md: 600px;
$modal-lg: 900px;
$line-height-base: 1.43;
$progress-bar-animation-timing: 0.5s linear infinite;
$border-radius: 0.375rem !default;

$colors: (
  'primary-hover': #22527b,

  'navbar': #004b8b,
  'navbar-btn-color': #dfdfdf,

  'btn-color': #333333,
  'btn-bg-hover': #e6e6e6,
  'btn-border-hover': #adadad,

  'row-bg': #f5f5f5,
  'row-bg-second': #f9f9f9,

  'current-day-color': #bc2424,
  'text-minor': #a9a9a9,
  'text-disabled': lightgray,
  'selected-color': #428bca,
  'selected-color-o15': #428bca26,
  'selected-color-o8': #428bcacc,

  'total-cell-bg': whitesmoke,
  'total-cell-bg-o8': rgba(245, 245, 245, 0.8),
  'booking-cell-bg': #eeffed,
  'estimate-cell-bg': #e7f0ff,
  'availability-cell-bg': #fff3cd,
  'disabled-cell-bg': #e9ecef,

  'gold': gold,
  'orange': orange,
  'dark-o1': rgba(0, 0, 0, 0.1),
  'dark-o4': rgba(0, 0, 0, 0.4),
  'dark-o7': rgba(0, 0, 0, 0.7),
  'resizeLineBackground': rgba(0, 0, 0, 0.05),
  'black': #000,
  'white': #fff,
  'grey': grey,
  'gauge-default-background': #e6ebf8, // add to palette
);

@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

/// TODO: make by functions and SCSS maps
[data-bs-theme='dark'] {
  --bs-primary: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  --bs-primary-hover: var(--bs-link-hover-color);

  --bs-navbar: #004b8b;

  --bs-btn-color: $gray-400;
  --bs-btn-bg-hover: #424649;
  --bs-btn-border-hover: #373b3e;

  --bs-row-bg: #25292c;
  --bs-row-bg-second: #2e363d;

  --bs-current-day-color: #fe6363;
  --bs-text-minor: #adb5bd;
  --bs-text-disabled: #6c757d;
  --bs-selected-color: #428bca;
  --bs-selected-color-o15: #428bca26;
  --bs-selected-color-o8: #428bcacc;

  --bs-total-cell-bg: #2e3338;
  --bs-total-cell-bg-o8: #2e3338cc;
  --bs-booking-cell-bg: #629060;
  --bs-estimate-cell-bg: #6a7d9c;
  --bs-availability-cell-bg: #c5a951a6;
  --bs-disabled-cell-bg: #2e3338; // Same color with --bs-total-cell-bg

  --bs-gold: gold;
  --bs-orange: orange;
}

@mixin trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin link-cell-trim {
  display: block;
  width: fit-content;
  max-width: 100%;
  a {
    display: block;
    @include trim;
  }
}

@mixin small-avatar {
  position: absolute;
  top: 50%;
  left: 0;

  margin-top: -15px;

  width: 30px;
  height: 30px;

  border-radius: 50%;
}

/* Для применения с flex контейнером. */
@mixin default-avatar {
  margin-right: 7px;

  min-width: 30px;
  width: 30px;
  height: 30px;

  border-radius: 50%;
}

@mixin disable-selecting {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

@mixin readonly-background {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 7px,
    rgba(233, 233, 233, 0.7) 7px,
    /* TODO: add theme variable */ rgba(233, 233, 233, 0.7) 14px
      /* TODO: add theme variable */
  );
}

@mixin action {
  border-radius: 0.35em;
  border: solid 1px transparent;

  &:hover {
    border: solid 1px var(--bs-border-color);
    background-color: var(--bs-btn-bg-hover);
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
  }
}

@mixin selected-row {
  color: var(--bs-light) !important;
  background: var(--bs-selected-color) !important;

  a {
    color: var(--bs-light) !important;
  }

  td {
    color: var(--bs-light) !important;
    background: var(--bs-selected-color) !important;

    a {
      color: var(--bs-light) !important;
    }
  }
}
