@import 'variables.scss';
@import 'mixins.scss';

/* Стили для всех типов вложенных таблиц*/

table.wp-nested-table {
  table-layout: fixed;
  border: none;
  width: 100%;
  margin-bottom: 20px;
  outline: none;

  tr:focus {
    outline: none;
  }

  .menu-cell {
    width: 36px;
    & > button {
      width: 100%;
      border: none;
      border-radius: 0;
      color: var(--bs-text-minor);
      transition: none;
      &.dropdown-toggle::after {
        display: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  & > thead > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    height: 35px;
    max-height: 35px;
    text-align: left;
    vertical-align: middle;
    cursor: default;
  }

  /*Стиль заголовка*/
  & > thead > tr > th:not(.wp-nested-table-toolbar-cell) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*Границы везде одинаковые*/
  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    @include cell-padding;
  }

  & > thead > tr > th,
  & > thead > tr > td {
    border-bottom-width: 2px;
    font-weight: 500;
    @supports (-moz-appearance: none) {
      font-weight: 501;
    }
  }
  & > tfoot > tr > th,
  & > tfoot > tr > td {
    border-top-width: 2px;
    font-weight: 500;
    @supports (-moz-appearance: none) {
      font-weight: 501;
    }
  }

  /*Отключение отступов у групп*/
  & > tbody > tr > td > div.form-group {
    margin: 0;
  }

  /*Подсветка выделенных строк*/
  & > tbody > tr.selected {
    & > td,
    & > td.menu-cell > button {
      background-color: $wp-nested-selected-color;
    }
  }

  button.input-group-text {
    transition: none;
  }

  /*Типы ячеек*/

  /*Ячейки, в которые встраиваются контролы*/
  & > tbody > tr > td.control-cell {
    padding: 0px !important;
    border-collapse: collapse !important;
    border-spacing: 0;
    vertical-align: middle;

    & > * {
      overflow: hidden;
    }
  }

  /*Ячейка с текстом (подходит и для заголовка колонки)*/
  & > tbody > tr > td.text-cell {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*Ячейка для чек-бокса*/
  & > tbody > tr > td.checkbox-cell {
    padding: 0 !important;
    text-align: center;
  }

  /*Селект, прямое указание на тег*/
  & > tbody > tr > td > select {
    border: none;
    height: 34px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  & > tbody > tr > td > input,
  & > tbody > tr > td > div > input {
    border: 0;
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }

  /*Ячейка для кнопки, прямое указание на тег*/
  & > tbody > tr > td > button {
    border: none;
    margin: 0 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    background: none;
  }

  /*Кнопка удаления строки*/
  & > tbody > tr > td > .remove-button {
    display: none;
  }

  & > tbody > tr:hover > td > .remove-button {
    display: inherit;
  }

  .avatar-cell {
    padding-left: 49px !important;
    img {
      left: 12px !important;
    }
  }
}

.wp-nested-table-hover {
  /*Подсветка строк при наведении*/
  & > tbody > tr:hover {
    background-color: var(--bs-row-bg);
  }

  /*Поддержка подсветки при наведении на стандартных элементах*/
  & > tbody > tr:hover select {
    background-color: var(--bs-row-bg);
  }

  /*На самом элементе при наведении напротив, обычный белый фон'*/
  & > tbody > tr:hover select:hover,
  & > tbody > tr > td > select:focus {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
  }
}

/*Поддержка подсветки при наведении на стандартных элементах*/
.wp-nested-table-hover > tbody > tr.selected select {
  background-color: var(--bs-tertiary-bg);
}

table.wp-nested-table:not(.stop-context) {
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(
      .valid-tooltip
    ):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
  .input-group > .form-floating:not(:first-child) > .form-control,
  .input-group > .form-floating:not(:first-child) > .form-select {
    margin-left: 0px;
  }
}
